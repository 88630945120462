import { createStore } from 'vuex'
import auth from './modules/auth';
import user from './modules/user';
import search from './modules/search';


export default createStore({

  modules: {
    auth,
    user,
    search
  }
})
