

import api from '@/api/api';

const state = {
    searchList: {},
};

const mutations = {
    SET_SEARCH_RESUALT(state, resualt) {
        state.searchList = resualt;
    },
};

const actions = {
    async fetchSearch({ commit }, payloud) {
        try {
            const response = await api.get('/v1/tasks/search', {
                params: {
                    q: payloud.q ?? undefined, 
                    type: payloud.type ?? undefined
                }
            });
            console.log(response, '==========> Data');

            commit('SET_SEARCH_RESUALT', response.data);
        } catch (error) {
            console.error('Failed to fetch user profile:', error);
        }
    }
};

const getters = {
    getSeachReasualt: state => state.searchList,
};

export default {
    // namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
