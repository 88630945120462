import { createRouter, createWebHistory } from 'vue-router'
import authMiddleware from '@/middleware/auth'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import( '@/layouts/DashboardLayout.vue'),
    beforeEnter: authMiddleware,
    redirect: '/tasks',
    meta: {
      title: "Dashboard"
    },
    children: [
      {
        path: '/profile/:id',
        name: "Profile",
        meta: {
          title: "Mening profilim"
        },
        component: () => import("@/views/Profile/IndexPage.vue")
      },
    ],
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: () => import( '@/layouts/TasksLayout.vue'),
    beforeEnter: authMiddleware,
    redirect: '/tasks/table',
    meta: {
      title: "Dashboard"
    },
    children: [
      {
        path: 'table',
        name: "TaskSituations",
        meta: {
          title: "Vazifa holatlari"
        },
        component: () => import("@/views/TaskSituations.vue"),
      },
      {
        path: 'calendar',
        name: "TaskCalendar",
        meta: {
          title: "Vazifa holatlari"
        },
        component: () => import("@/views/TasksCalendar.vue"),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/LoginPage.vue'),
    beforeEnter: authMiddleware,
    meta: {
      title: "Login"
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to) => {
  document.title = to.meta['title'] || to.name;
});

export default router
